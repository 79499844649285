<template>
  <div class="content_box">
    <el-card>
      <el-row>
        <el-form
          ref="mackOrderFormRef"
          :inline="true"
          :model="formData"
          :rules="formDataRules"
          class="demo-form-inline"
        >
          <el-form-item label="订单类型:">
            <el-select v-model="formData.type" placeholder="制作订单类型">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="userId" label="用户名:">
            <el-select
              v-model="formData.userId"
              filterable
              @focus="onFocus"
              remote
              placeholder="请输入关键词"
              :remote-method="userRemoteFun"
              @change="userIdSelectChange"
            >
              <el-option
                v-for="item in userOption"
                :key="item.id"
                :label="item.userName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户名称:">
            <el-input
              v-model="formData.companyName"
              disabled
              placeholder="客户名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人:">
            <!-- <el-select
              v-model="formData.contactDetailId"
              filterable
              @change="userAddressSelectChange"
            >
              <el-option
                v-for="item in userAddressList"
                :key="item.id"
                :label="item.receiverName"
                :value="item.id"
              >
              </el-option>
            </el-select> -->

            <el-autocomplete
              class="inline-input"
              v-model="formData.customerName"
              :fetch-suggestions="userAddressQuery"
              placeholder="请输入内容"
              @select="userAddressSelect"
            ></el-autocomplete>
          </el-form-item>
          <!-- <el-form-item prop="customerName" label="联系人:">
            <el-input
              v-model="formData.customerName"
              placeholder="联系人"
            ></el-input>
          </el-form-item> -->
          <el-form-item prop="customerPhone" label="联系电话:">
            <el-input
              v-model="formData.customerPhone"
              placeholder="联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item prop="customerAddress" label="地址:">
            <el-input
              style="width: 400px"
              v-model="formData.customerAddress"
              placeholder="地址"
            ></el-input>
          </el-form-item>
          <el-form-item prop="discount" label="折扣:">
            <el-input
              v-model="formData.discount"
              placeholder="请输入折扣如:9.5"
            ></el-input>
          </el-form-item>
          <el-form-item prop="taxRate" label="税率(%):">
            <el-input v-model="formData.taxRate" placeholder="税率"></el-input>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input
              v-model="formData.remark"
              placeholder="订单备注内容"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>

      <el-row>
        <el-table
          :summary-method="getSummaries"
          :show-summary="true"
          :data="formData.productList"
          border
          :max-height="600"
        >
          <el-table-column label="#" width="60" type="index"></el-table-column>
          <el-table-column
            label="商品名称"
            prop="productName"
          ></el-table-column>
          <el-table-column label="商品型号" prop="modelName"></el-table-column>
          <el-table-column
            label="商品规格"
            prop="productSpecs"
          ></el-table-column>
          <el-table-column label="商品数量" prop="count"></el-table-column>
          <el-table-column label="商品单价" prop="price"></el-table-column>
          <el-table-column label="商品总价格">
            <template slot-scope="scope">
              {{ scope.row.count * scope.row.price }}
            </template>
          </el-table-column>
          <el-table-column label="商品备注" prop="remark"></el-table-column>
          <el-table-column label="编辑">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="openEditFormDialog(scope.row)"
                type="primary"
                icon="el-icon-edit"
                >编辑</el-button
              >
              <el-button
                size="mini"
                @click="deleteProduct(scope.row)"
                type="danger"
                icon="el-icon-delete"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <el-row style="margin-top: 20px">
        <el-button type="info" @click="importOrderDialogVisabled = true"
          >导入已有订单</el-button
        >
        <div style="float: right">
          <el-button type="success" @click="addProductDialogVisabled = true"
            >新增商品</el-button
          >
          <el-button type="primary" @click="orderSubmit">保存订单</el-button>
          <el-button type="info" @click="cancelOrder">取消订单</el-button>
        </div>
      </el-row>
    </el-card>

    <!-- 新增商品对话框 -->
    <el-dialog
      title="新增商品"
      :visible.sync="addProductDialogVisabled"
      @close="addFormDialogClose"
    >
      <el-form
        ref="addProductFormRef"
        :model="addProductForm"
        :rules="addProductFormRules"
        label-width="100"
      >
        <el-form-item prop="productName" label="商品名称">
          <!-- <el-autocomplete
            style="width: 100%"
            v-model="addProductForm.productName"
            autocomplete="off"
            :fetch-suggestions="querySearch"
            placeholder="商品名称"
            @select="productSelect"
          ></el-autocomplete> -->

          <el-select
            v-model="addProductForm.productId"
            filterable
            @focus="onFocus"
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="querySearch"
            @change="productSelect"
          >
            <el-option
              v-for="item in productOptions"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
              <div>
                <el-image
                  style="height: 30px; width: 30px;vertical-align: middle;"
                  :src="item.imageUrl"
                />
                <span style="vertical-align: middle;">
                  {{ item.value }}
                </span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商品型号">
          <el-select
            v-model="addProductForm.modelName"
            placeholder="请输入关键词"
          >
            <el-option
              v-for="item in modelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商品规格">
          <el-select
            v-model="addProductForm.productSpecs"
            placeholder="请输入关键词"
            @change="specsOptionsChange"
          >
            <el-option
              v-for="item in specsOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="count" label="商品数量">
          <el-input
            v-model="addProductForm.count"
            autocomplete="off"
            placeholder="商品数量"
          ></el-input>
        </el-form-item>
        <el-form-item prop="price" label="商品价格">
          <el-input
            v-model="addProductForm.price"
            autocomplete="off"
            placeholder="商品价格"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品备注">
          <el-input
            v-model="addProductForm.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addProductDialogVisabled = false">取 消</el-button>
        <el-button type="primary" @click="addFormDialogConfim">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 编辑商品对话框 -->
    <el-dialog
      title="编辑商品"
      :visible.sync="editProductDialogVisabled"
      @close="editFormDialogClose"
    >
      <el-form
        ref="editProductFormRef"
        :model="editProductForm"
        :rules="editProductFormRules"
        label-width="100"
      >
        <el-form-item prop="productName" label="商品名称">
          <el-input
            v-model="editProductForm.productName"
            disabled
            autocomplete="off"
            placeholder="商品名称"
          ></el-input>
        </el-form-item>

        <el-form-item prop="productName" label="商品型号">
          <el-input
            v-model="editProductForm.modelName"
            disabled
            autocomplete="off"
            placeholder="商品型号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="productName" label="商品规格">
          <el-input
            v-model="editProductForm.productSpecs"
            disabled
            autocomplete="off"
            placeholder="商品规格"
          ></el-input>
        </el-form-item>

        <el-form-item prop="count" label="商品数量">
          <el-input
            v-model="editProductForm.count"
            autocomplete="off"
            placeholder="商品数量"
          ></el-input>
        </el-form-item>
        <el-form-item prop="price" label="商品价格">
          <el-input
            v-model="editProductForm.price"
            autocomplete="off"
            placeholder="商品价格"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品备注">
          <el-input
            v-model="editProductForm.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editProductDialogVisabled = false">取 消</el-button>
        <el-button type="primary" @click="editFormDialogConfim"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 导入商品对话框 -->
    <el-dialog
      title="导入订单"
      :visible.sync="importOrderDialogVisabled"
      @close="importOrderDialogClose"
    >
      <!-- 订单查询表单 -->
      <el-row>
        <el-form
          ref="importOrderFormRef"
          :inline="true"
          :model="importOrderForm"
          :rules="importOrderFormRules"
          class="demo-form-inline"
        >
          <el-form-item prop="orderNumber" label="订单编号:">
            <el-input
              v-model="importOrderForm.orderNumber"
              placeholder="请输入订单编号"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="getImprotOrderData"
              >订单查询</el-button
            >
          </el-form-item>
        </el-form>
      </el-row>
      <!-- 当前信息数据 -->
      <el-row>
        <el-table
          :data="importOrderForm.orderData"
          @selection-change="improtOrderTableSelectionChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column label="#" width="60" type="index"></el-table-column>
          <el-table-column
            label="商品名称"
            prop="productName"
          ></el-table-column>
          <el-table-column label="型号规格">
            <template slot-scope="scope">
              {{
                (scope.row.modelName || scope.row.productModel || '无') +
                `(${scope.row.productSpecs || '无'})`
              }}
            </template>
          </el-table-column>
          <el-table-column label="商品数量" prop="count"></el-table-column>
          <el-table-column label="商品单价" prop="price"></el-table-column>
          <el-table-column label="商品总价格">
            <template slot-scope="scope">
              {{ scope.row.count * scope.row.price }}
            </template>
          </el-table-column>
          <el-table-column label="商品备注" prop="remark"></el-table-column>
        </el-table>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="importOrderDialogVisabled = false">取 消</el-button>
        <el-button type="primary" @click="improtOrderDataConfim"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  addMackOrder,
  getOrderDataByNumber,
  getMackOrderTypeOptions,
} from '@/api/order.js'
import { getUserOptions, getUserAddressList } from '@/api/user.js'
import { getFilterProduct } from '@/api/product.js'
import { validateNumber } from '@/utils/validate.js'
import { iosOnFocus } from '@/utils/iosPlatform.js'

export default {
  data() {
    let validateTaxRate = (rule, value, callback) => {
      if (validateNumber(value)) {
        var number = Number(value)

        if (number < 0 || number > 100) {
          callback(new Error('税率必须为0-100的数字类型'))
        } else {
          callback()
        }
      } else {
        callback(new Error('当前类型为非数字类型'))
      }
    }

    let validateDiscount = (rule, value, callback) => {
      if (validateNumber(value)) {
        var number = Number(value)

        if (number < 0 || number > 10) {
          callback(new Error('折扣必须为0-10的数字类型'))
        } else {
          callback()
        }
      } else {
        callback(new Error('当前类型为非数字类型'))
      }
    }

    let validateNumberValid = (rule, value, callback) => {
      if (validateNumber(value)) {
        var number = Number(value)

        if (number < 0) {
          callback(new Error('当前数据不能为负数'))
        } else {
          callback()
        }
      } else {
        callback(new Error('当前类型为非数字类型'))
      }
    }
    return {
      typeOptions: [],
      // 表单数据
      formData: {
        type: 0,
        userId: '',
        contactDetailId: null,
        companyName: '',
        customerName: '',
        customerPhone: '',
        customerAddress: '',
        taxRate: '',
        discount: '',
        productList: [],
        remark: '',
      },
      // 表单验证规则
      formDataRules: {
        userId: [
          {
            required: true,
            message: '请选择当前订单所属用户',
            trigger: 'blur',
          },
        ],
        companyName: [
          { required: true, message: '请输入客户名称', trigger: 'blur' },
        ],
        customerName: [
          // { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        customerPhone: [
          // { required: true, message: '请输入联系方式', trigger: 'blur' },
        ],
        customerAddress: [
          // { required: true, message: '请输入联系人地址', trigger: 'blur' },
        ],
        discount: [
          {
            validator: validateDiscount,
            trigger: 'blur',
          },
        ],
        taxRate: [
          { required: true, message: '请输入税率', trigger: 'blur' },
          {
            validator: validateTaxRate,
            trigger: 'blur',
          },
        ],
      },
      // 用户名选项框
      userOption: [],
      // 用户地址信息
      userAddressList: [],
      // 商品远程搜索
      productOptions: [],
      // 新增表单对话框显示隐藏
      addProductDialogVisabled: false,
      // 新增商品表单数据
      addProductForm: {
        productId: null,
        productName: '',
        modelName: null,
        productSpecs: null,
        count: '1',
        price: '',
        remark: '',
      },
      addProductFormRules: {
        productName: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur',
          },
        ],
        count: [
          {
            required: true,
            message: '请输入商品数量',
            trigger: 'blur',
          },
          {
            validator: validateNumberValid,
            trigger: 'blur',
          },
        ],
        price: [
          {
            required: true,
            message: '请输入商品价格',
            trigger: 'blur',
          },
          {
            validator: validateNumberValid,
            trigger: 'blur',
          },
        ],
      },
      modelOptions: [],
      specsOptions: [],
      // 编辑商品对话框显示隐藏
      editProductDialogVisabled: false,
      editProductForm: {
        productId: null,
        productName: '',
        modelName: null,
        productSpecs: null,
        count: '',
        price: '1',
        remark: '',
      },
      editProductFormRules: {
        productName: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur',
          },
        ],
        count: [
          {
            required: true,
            message: '请输入商品数量',
            trigger: 'blur',
          },
          {
            validator: validateNumberValid,
            trigger: 'blur',
          },
        ],
        price: [
          {
            required: true,
            message: '请输入商品价格',
            trigger: 'blur',
          },
          {
            validator: validateNumberValid,
            trigger: 'blur',
          },
        ],
      },
      // 导入已有订单
      importOrderDialogVisabled: false,
      importOrderForm: {
        orderNumber: '',
        orderData: [],
        selectOrderData: [],
      },
      importOrderFormRules: {
        orderNumber: [
          { required: true, message: '请输入订单号', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    async getTypeOptions() {
      let { data } = await getMackOrderTypeOptions()
      this.typeOptions = data
    },
    // 表格尾行合计行
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        // 第一列索引
        if (index === 0) {
          sums[index] = '总价'
          return
        }

        // 第二列商品名称
        if (index === 4) {
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] += ' 件'
          } else {
            sums[index] = 'N/A'
          }
          return
        }

        // 第四列总价
        if (index === 6) {
          const priceArr = data.map((item) => {
            return Number(item['price'] * item['count'])
          })

          if (!priceArr.every((value) => isNaN(value))) {
            sums[index] = priceArr
              .reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
              .toFixed(2)
            sums[index] += ' 元'
            return
          } else {
            sums[index] = 'N/A'
            return
          }
        }

        sums[index] = ''
      })

      return sums
    },
    // 获取商品的数据
    async querySearch(queryString) {
      if (queryString !== '') {
        let { data } = await getFilterProduct(queryString)
        this.productOptions = data
      } else {
        this.productOptions = []
      }

      // console.log(queryString)
      // let { data } = await getFilterProduct(queryString)
      // // 调用 callback 返回建议列表的数据
      // cb(data)
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    addFormDialogClose() {
      this.productOptions = []
      this.modelOptions = []
      this.specsOptions = []
      this.addProductForm.productId = null
      this.addProductForm.modelName = null
      this.addProductForm.productSpecs = null
      this.resetForm('addProductFormRef')
    },
    editFormDialogClose() {
      this.resetForm('editProductFormRef')
    },
    // 确认新增商品
    addFormDialogConfim() {
      this.$refs.addProductFormRef.validate((valid) => {
        if (valid) {
          let filterEntity = this.formData.productList.filter((item) => {
            return (
              item.productId == this.addProductForm.productId &&
              item.modelName == this.addProductForm.modelName &&
              item.productSpecs == this.addProductForm.productSpecs
            )
          })

          if (filterEntity.length > 0) {
            return this.$message.error('当前订单中已经存在该商品')
          } else {
            this.formData.productList.push({
              productId: this.addProductForm.productId,
              productName: this.addProductForm.productName,
              modelName: this.addProductForm.modelName,
              productSpecs: this.addProductForm.productSpecs,
              count: parseInt(this.addProductForm.count),
              price: Number(this.addProductForm.price).toFixed(2),
              remark: this.addProductForm.remark,
            })

            //this.resetForm('addProductFormRef')

            this.addProductDialogVisabled = false
            this.$message.success('添加商品成功')
          }
        } else {
          return this.$message.error('当前商品参数填写不正确.')
        }
      })
    },
    openEditFormDialog(data) {
      this.editProductForm = { ...data }
      this.editProductDialogVisabled = true
    },
    // 确认编辑商品
    editFormDialogConfim() {
      this.$refs.editProductFormRef.validate((valid) => {
        if (valid) {
          let filterEntity = this.formData.productList.filter((item) => {
            return (
              item.productId == this.editProductForm.productId &&
              item.modelName == this.editProductForm.modelName &&
              item.productSpecs == this.editProductForm.productSpecs
            )
          })

          if (filterEntity.length > 0) {
            this.formData.productList.forEach((item, index, arr) => {
              if (
                item.productId == this.editProductForm.productId &&
                item.modelName == this.editProductForm.modelName &&
                item.productSpecs == this.editProductForm.productSpecs
              ) {
                arr[index].productId = this.editProductForm.productId
                arr[index].productName = this.editProductForm.productName
                arr[index].count = parseInt(this.editProductForm.count)
                arr[index].price = Number(this.editProductForm.price).toFixed(2)
                arr[index].remark = this.editProductForm.remark
              }
            })
          } else {
            this.$message.error('编辑失败,当前商品不存在.')
          }

          this.resetForm('editProductFormRef')

          this.editProductDialogVisabled = false
          this.$message.success('编辑商品成功')
        } else {
          return this.$message.error('当前商品参数填写不正确.')
        }
      })
    },
    // 从订单中删除商品
    deleteProduct(row) {
      this.$confirm('当前操作将会将商品从订单中删除是否确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.formData.productList = this.formData.productList.filter(
            (item) => {
              return !(
                item.productId == row.productId &&
                item.modelName == row.modelName &&
                item.productSpecs == row.productSpecs
              )
            }
          )

          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 提交采购订单
    async orderSubmit() {
      this.$refs.mackOrderFormRef.validate(async (valid) => {
        if (valid) {
          if (this.formData.productList.length <= 0) {
            return this.$message.error('不允许提交商品数量为空的订单')
          }

          await addMackOrder(this.formData)
          this.$router.push({ path: '/MackSaleOrder' })
        } else {
          this.$message.error('当前订单信息不完整请填写完整之后重新进行提交!')
        }
      })
    },
    cancelOrder() {
      this.$router.push({ path: '/MackSaleOrder' })
    },
    productSelect(id) {
      let item = this.productOptions.filter((item) => {
        return item.id == id
      })[0]
      //      console.log(item)
      this.addProductForm.productName = item.productName
      this.addProductForm.price = item.price

      if (item.modelOptions && item.modelOptions.length > 0) {
        this.modelOptions = item.modelOptions
        this.addProductForm.modelName = item.modelOptions[0].value
      }

      // if (item.specsOptions && item.specsOptions.length > 0) {
      //   this.specsOptions = item.specsOptions
      //   this.addProductForm.productSpecs = item.specsOptions[0].value
      // }

      if (item.attribute && item.attribute.length > 0) {
        this.specsOptions = item.attribute
        this.addProductForm.productSpecs = item.attribute[0].value
        this.addProductForm.price = item.attribute[0].price
      }
    },
    specsOptionsChange(id) {
      if (this.specsOptions.some((u) => u.value == id)) {
        let item = this.specsOptions.filter((i) => {
          return i.value == id
        })[0]

        this.addProductForm.productSpecs = item.value
        this.addProductForm.price = item.price
      }
    },
    importOrderDialogClose() {
      this.resetForm('importOrderFormRef')
      this.importOrderForm.orderData = []
      this.importOrderForm.selectOrderData = []
    },
    // 当前选项表中,更改选中项时的表格
    improtOrderTableSelectionChange(val) {
      this.importOrderForm.selectOrderData = val
    },
    async getImprotOrderData() {
      let { data } = await getOrderDataByNumber(
        this.importOrderForm.orderNumber
      )
      this.importOrderForm.selectOrderData = []

      this.importOrderForm.orderData = data
    },
    // 导入当前订单选择项
    improtOrderDataConfim() {
      let arr = []

      this.importOrderForm.selectOrderData.forEach((item) => {
        let entity = this.formData.productList.filter((i) => {
          return (
            item.productId == i.productId &&
            item.modelName == i.modelName &&
            item.productSpecs == i.productSpecs
          )
        })

        if (entity.length > 0) {
          arr.push({ ...item })
        }
      })

      if (arr.length > 0) {
        let msg = ''
        arr.forEach((item) => {
          msg += `[${item.productName}]`
        })
        return this.$message.info('当前订单中已经存在这些商品,' + msg)
      }

      this.formData.productList = [
        ...this.formData.productList,
        ...this.importOrderForm.selectOrderData,
      ]

      this.importOrderDialogVisabled = false
    },
    // 用户远程搜索
    async userRemoteFun(key) {
      if (key !== '') {
        let { data } = await getUserOptions(key)
        this.userOption = data
      }
    },
    // 用户名选中更改
    async userIdSelectChange(dataId) {
      let entity = this.userOption.filter((item) => {
        return item.id == dataId
      })[0]

      this.formData.companyName = entity.customerName

      this.formData.customerName = ''
      this.formData.customerPhone = ''
      this.formData.customerAddress = ''

      this.formData.contactDetailId = null

      let { data } = await getUserAddressList(dataId)
      this.userAddressList = data
    },
    // 用户快捷地址选择更改
    userAddressSelectChange(dataId) {
      let entity = this.userAddressList.filter((item) => {
        return item.id == dataId
      })[0]

      this.formData.customerName = entity.receiverName
      this.formData.customerPhone = entity.receiverPhone
      this.formData.customerAddress = entity.receiverAddress
    },
    // 用户联系方式
    userAddressQuery(keyworld, cb) {
      var restaurants = [...this.userAddressList]
      var results = keyworld
        ? restaurants.filter((item) => {
            return item.receiverName.indexOf(keyworld) === 0
          })
        : restaurants

      let resultData = results.map((item) => {
        return { ...item, value: item.receiverName }
      })

      // 调用 callback 返回建议列表的数据
      cb(resultData)
    },
    // 用户联系方式快捷选择
    userAddressSelect(data) {
      this.formData.customerName = data.receiverName
      this.formData.customerPhone = data.receiverPhone
      this.formData.customerAddress = data.receiverAddress
    },
    onFocus(event) {
      iosOnFocus.call(this, event)
    },
  },
  created() {
    this.getTypeOptions()
  },
  computed: {},
}
</script>
<style lang="scss" scoped>
.content_box {
  width: 100%;
  height: 100%;
  // background-color: white;
}
.el-form-item {
  margin-bottom: 20px;
}
.el-select {
  width: 100%;
}
</style>
